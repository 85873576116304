



























import { CarDealerSeller } from '@/api/interfaces/cardealerseller'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SelectSearchCarDealerSellers',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateEditCarDealerSeller: () => import('../dialog/createEditCarDealerSeller.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    carDealer: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  watch: {
    carDealer: {
      immediate: true,
      async handler () {
        if (this.carDealer) {
          this.carDealerSellerList = await this.load(this.carDealer)
        }
        if (this.value) {
          if (!this.findSellerInList(this.value['@id'])) {
            this.$emit('input', null)
          }
        }
      },
    },
    value: {
      immediate: true,
      handler () {
        if (this.value && this.value.id) {
          this.carDealerSellerList = [this.value]
        }
      },
    },
  },
  data () {
    return {
      dialogCarDealerSeller: false,
      isSubmiting: false,
      carDealerSellerEdit: {},
      carDealerSellerList: [] as Array<CarDealerSeller>,
    }
  },
  methods: {
    ...mapActions('carDealerSellerList', {
      load: 'load',
      post: 'post',
    }),
    itemText (item: CarDealerSeller) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return `${item.lastName} ${item.firstName}`
    },
    onInput (uri: string) {
      const carDealerSeller = this.findSellerInList(uri)
      this.$emit('input', carDealerSeller)
    },
    findSellerInList (uri: string) {
      return (this.carDealerSellerList as Array<CarDealerSeller>).find(
        x => x['@id'] === uri,
      )
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    toggleModal () {
      this.dialogCarDealerSeller = !this.dialogCarDealerSeller
    },
    editCarDealerSeller () {
      this.carDealerSellerEdit = { ...this.value }
      this.toggleModal()
    },
    onClose () {
      this.dialogCarDealerSeller = false
      this.carDealerSellerEdit = {}
    },
    onCarDealerSellerCreated (carDealerSeller: CarDealerSeller) {
      this.$emit('input', carDealerSeller)
    },
    onCarDealerSellerUpdated (carDealerSeller: CarDealerSeller) {
      this.$emit('input', carDealerSeller)
    },
  },
})
